import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import hu from './locales/hu.json';
import sl from './locales/sl.json';
import ro from './locales/ro.json';

const messages = {
  en,
  hu,
  sl,
  ro,
};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
