import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; 
import i18n from './i18n';

// Import global CSS
import '@/assets/scss/tailwind.scss'; // TailwindCSS (or any other global styles)
import '@/assets/libs/@iconscout/unicons/css/line.css';
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '@/assets/libs/animate.css/animate.min.css';

// Additional plugin imports
import VueDatePicker from '@vuepic/vue-datepicker';
import VueSmoothScroll from 'vue3-smooth-scroll';
import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App);


// Watch route change to update the locale
router.beforeEach((to, from, next) => {
  const locale = to.params.locale || 'en';
  i18n.global.locale = locale;
  next();
});

app.use(router);
app.use(VueDatePicker);
app.use(VueSmoothScroll);
app.use(store);
app.use(i18n);

app.mount('#app');
