<template>
    <div id="app">
        <!-- You could add a locale selector or header here if needed -->
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
    computed: {
        currentLocale() {
            // Using the current locale from the route params
            return this.$route.params.locale || 'en';
        }
    }
};
</script>

<style>
/* Global styles can be added here if needed */
</style>
