import config from './config';

const endpoints = {
  // Auth Endpoints
  login: `${config.apiBaseUrl}/login`,
  logout: `${config.apiBaseUrl}/logout`,
  user: `${config.apiBaseUrl}/user`,
  test: `${config.apiBaseUrl}/test`,

  // User Endpoints
  getUser: `${config.apiBaseUrl}/user`,
  updateUser: `${config.apiBaseUrl}/user/update`,
  deleteUser: `${config.apiBaseUrl}/user/delete`,
  setNotifications: `${config.apiBaseUrl}/user/notifications`,

  // Diplomas Endpoints
  diplomas: `${config.apiBaseUrl}/diplomas`,
  diplomaDownload: (diplomaId) => `${config.apiBaseUrl}/diplomas/${diplomaId}/download`,

  // Contests Endpoints
  contests: `${config.apiBaseUrl}/contests`,
  contestDetail: (contestId) => `${config.apiBaseUrl}/contests/${contestId}`,

  // Works Endpoints
  works: `${config.apiBaseUrl}/works`,
  workDetail: (workId) => `${config.apiBaseUrl}/works/${workId}`,
  createWork: `${config.apiBaseUrl}/works`,
  updateWork: (workId) => `${config.apiBaseUrl}/works/${workId}`,

  // Scores Endpoints
  scores: `${config.apiBaseUrl}/scores`,
  scoreDetail: (scoreId) => `${config.apiBaseUrl}/scores/${scoreId}`,
  createScore: `${config.apiBaseUrl}/scores`,
  updateScore: (scoreId) => `${config.apiBaseUrl}/scores/${scoreId}`,
};

export default endpoints;
