// store/index.js
import { createStore } from 'vuex';
import axios from 'axios';
import endpoints from '../api-endpoints';

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      name: '',
      user: null,
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user.loggedIn = payload.loggedIn;
      state.user.name = payload.name;
      state.user.user = payload.user;
    },
  },
  actions: {
    async checkAuth({ commit }) {
      const token = localStorage.getItem('auth_token');
      if (token) {
        try {
          const response = await axios.get(endpoints.user, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          commit('setUser', {
            loggedIn: true,
            user: response.data,
            name: response.data.first_name[0] + response.data.last_name[0],
          });
        } catch (error) {
          console.error('Authentication check failed', error?.response);
          commit('setUser', {
            loggedIn: false,
            name: '',
          });
        }
      } else {
        commit('setUser', {
          loggedIn: false,
          name: '',
        });
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.user.loggedIn;
    },
    getUser(state) {
      return state.user.user;
    },
    getUserName(state) {
      return state.user.name;
    },
  },
});

export default store;
