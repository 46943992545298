import { createWebHistory, createRouter } from 'vue-router';
import i18n from '@/i18n';

const routes = [
  {
    path: '/:locale(en|ro|hu|sl)?', // Route with optional locale
    beforeEnter: (to, from, next) => {
      // Set the locale based on the route parameter
      const locale = to.params.locale;
      if (['en', 'ro', 'hu', 'sl'].includes(locale)) {
        i18n.global.locale = locale;
      } else {
        i18n.global.locale = 'en'; // Default to English if locale isn't recognized
      }
      console.log('Router locale set to:', i18n.global.locale); // Debug statement
      next();
    },
    children: [
      {
        path: "/",
        name: "landing",
        component: () => import('@/pages/landing.vue'),
      },
      {
        path: "login",
        name: "auth-login",
        component: () => import('@/pages/auth-pages/auth-login.vue'),
      },
      {
        path: "register",
        name: "auth-signup",
        component: () => import('@/pages/auth-pages/auth-signup.vue'),
      },
      {
        path: "forgot-password",
        name: "auth-re-password",
        component: () => import('@/pages/auth-pages/auth-re-password.vue'),
      },
      {
        path: "auth-lock-screen",
        name: "auth-lock-screen",
        component: () => import('@/pages/auth-pages/auth-lock-screen.vue'),
      },
      {
        path: "contact",
        name: "contact-one",
        component: () => import('@/pages/contact-one.vue'),
      },
      {
        path: "home",
        name: "index",
        component: () => import('@/pages/index/index.vue'),
      },
      {
        path: "send-art",
        name: "send-art",
        component: () => import('@/pages/send-art.vue'),
      },
      {
        path: "page-thankyou",
        name: "page-thankyou",
        component: () => import('@/pages/special-pages/page-thankyou.vue'),
      },
      {
        path: "blog",
        name: "blog",
        component: () => import('@/pages/blog/blog.vue'),
      },
      {
        path: "blog-detail",
        name: "blog-details",
        component: () => import('@/pages/blog/blog-detail.vue'),
      },
  
      {
        path: "blog-detail/:id",
        name: "blog-detail",
        component: () => import('@/pages/blog/blog-detail.vue'),
      },
      {
        path: "about",
        name: "about",
        component: () => import('@/pages/about.vue'),
        },
      {
        path: "rulebook",
        name: "page-rules",
        component: () => import('@/pages/utility/page-rules.vue'),
      },
      {
        path: "user-profile",
        name: "user-profile",
        component: () => import('@/pages/account/user-profile.vue'),
      },
      {
        path: "user-billing",
        name: "user-billing",
        component: () => import('@/pages/account/user-billing.vue'),
      },
      {
        path: "user-diploma",
        name: "user-diploma",
        component: () => import('@/pages/account/user-diploma.vue'),
      },
      {
        path: "user-invoice",
        name: "user-invoice",
        component: () => import('@/pages/account/user-invoice.vue'),
      },
      {
        path: "user-social",
        name: "user-social",
        component: () => import('@/pages/account/user-social.vue'),
      },
      {
        path: "user-notification",
        name: "user-notification",
        component: () => import('@/pages/account/user-notification.vue'),
      },
      {
        path: "user-setting",
        name: "user-setting",
        component: () => import('@/pages/account/user-setting.vue'),
      },
      {
        path: "course-listing",
        name: "course-listing",
        component: () => import('@/pages/course/course-listing.vue'),
      },
      {
        path: "page-job-grid",
        name: "page-job-grid",
        component: () => import('@/pages/job-careers/page-job-grid.vue'),
      },
      {
        path: "page-job-detail",
        name: "page-job-details",
        component: () => import('@/pages/job-careers/page-job-detail.vue'),
      },
      {
        path: "page-job-detail/:id",
        name: "page-job-detail",
        component: () => import('@/pages/job-careers/page-job-detail.vue'),
      },
      {
        path: "page-job-apply",
        name: "page-job-apply",
        component: () => import('@/pages/job-careers/page-job-apply.vue'),
      },
      {
        path: "page-job-post",
        name: "page-job-post",
        component: () => import('@/pages/job-careers/page-job-post.vue'),
      },
      {
        path: "page-job-career",
        name: "page-job-career",
        component: () => import('@/pages/job-careers/page-job-career.vue'),
      },
      {
        path: "results",
        name: "results",
        component: () => import('@/pages/results.vue'),
      },
      {
        path: "page-job-candidate-detail",
        name: "page-job-candidate-detail",
        component: () => import('@/pages/job-careers/page-job-candidate-detail.vue'),
      },
      {
        path: "page-job-companies",
        name: "page-job-companies",
        component: () => import('@/pages/job-careers/page-job-companies.vue'),
      },
      {
        path: "page-job-company-detail",
        name: "page-job-company-detail",
        component: () => import('@/pages/job-careers/page-job-company-detail.vue'),
      },
      {
        path: "email-confirmation",
        name: "email-confirmation",
        component: () => import('@/pages/email-template/email-confirmation.vue'),
      },
      {
        path: "email-password-reset",
        name: "email-password-reset",
        component: () => import('@/pages/email-template/email-password-reset.vue'),
      },
      {
        path: "email-alert",
        name: "email-alert",
        component: () => import('@/pages/email-template/email-alert.vue'),
      },
      {
        path: "email-invoice",
        name: "email-invoice",
        component: () => import('@/pages/email-template/email-invoice.vue'),
      },
      {
        path: "auth-signup-success",
        name: "auth-signup-success",
        component: () => import('@/pages/auth-pages/auth-signup-success.vue'),
      },
      {
        path: "page-terms",
        name: "page-terms",
        component: () => import('@/pages/utility/page-terms.vue'),
      },
      {
        path: "page-privacy",
        name: "page-privacy",
        component: () => import('@/pages/utility/page-privacy.vue'),
      },
      {
        path: "contact-detail",
        name: "contact-detail",
        component: () => import('@/pages/contact/contact-detail.vue'),
      },
      {
        path: "portfolio-modern-two",
        name: "portfolio-modern-two",
        component: () => import('@/pages/portfolio/modern-portfolio/portfolio-modern-two.vue'),
      },
      {
        path: "portfolio-modern-three",
        name: "portfolio-modern-three",
        component: () => import('@/pages/portfolio/modern-portfolio/portfolio-modern-three.vue'),
      },
      {
        path: "sponsors",
        name: "sponsors",
        component: () => import('@/pages/sponsors.vue'),
      },
      {
        path: "portfolio-modern-five",
        name: "portfolio-modern-five",
        component: () => import('@/pages/portfolio/modern-portfolio/portfolio-modern-five.vue'),
      },
      {
        path: "portfolio-modern-six",
        name: "portfolio-modern-six",
        component: () => import('@/pages/portfolio/modern-portfolio/portfolio-modern-six.vue'),
      },
      {
        path: "portfolio-classic-two",
        name: "portfolio-classic-two",
        component: () => import('@/pages/portfolio/classic-portfolio/portfolio-classic-two.vue'),
      },
      {
        path: "portfolio-classic-three",
        name: "portfolio-classic-three",
        component: () => import('@/pages/portfolio/classic-portfolio/portfolio-classic-three.vue'),
      },
      {
        path: "rate",
        name: "rate",
        component: () => import('@/pages/rate.vue'),
      },
      {
        path: "portfolio-classic-five",
        name: "portfolio-classic-five",
        component: () => import('@/pages/portfolio/classic-portfolio/portfolio-classic-five.vue'),
      },
      {
        path: "portfolio-classic-six",
        name: "portfolio-classic-six",
        component: () => import('@/pages/portfolio/classic-portfolio/portfolio-classic-six.vue'),
      },
      {
        path: "portfolio-creative-two",
        name: "portfolio-creative-two",
        component: () => import('@/pages/portfolio/creative-portfolio/portfolio-creative-two.vue'),
      },
      {
        path: "portfolio-creative-three",
        name: "portfolio-creative-three",
        component: () => import('@/pages/portfolio/creative-portfolio/portfolio-creative-three.vue'),
      },
      {
        path: "portfolio-creative-four",
        name: "portfolio-creative-four",
        component: () => import('@/pages/portfolio/creative-portfolio/portfolio-creative-four.vue'),
      },
      {
        path: "portfolio-creative-five",
        name: "portfolio-creative-five",
        component: () => import('@/pages/portfolio/creative-portfolio/portfolio-creative-five.vue'),
      },
      {
        path: "portfolio-creative-six",
        name: "portfolio-creative-six",
        component: () => import('@/pages/portfolio/creative-portfolio/portfolio-creative-six.vue'),
      },
      {
        path: "portfolio-masonry-two",
        name: "portfolio-masonry-two",
        component: () => import('@/pages/portfolio/masonry-portfolio/portfolio-masonry-two.vue'),
      },
      {
        path: "portfolio-masonry-three",
        name: "portfolio-masonry-three",
        component: () => import('@/pages/portfolio/masonry-portfolio/portfolio-masonry-three.vue'),
      },
      {
        path: "portfolio-masonry-four",
        name: "portfolio-masonry-four",
        component: () => import('@/pages/portfolio/masonry-portfolio/portfolio-masonry-four.vue'),
      },
      {
        path: "portfolio-masonry-five",
        name: "portfolio-masonry-five",
        component: () => import('@/pages/portfolio/masonry-portfolio/portfolio-masonry-five.vue'),
      },
      {
        path: "portfolio-masonry-six",
        name: "portfolio-masonry-six",
        component: () => import('@/pages/portfolio/masonry-portfolio/portfolio-masonry-six.vue'),
      },
      {
        path: "portfolio-detail-one",
        name: "portfolio-detail-ones",
        component: () => import('@/pages/portfolio/detail-portfolio/portfolio-detail-one.vue'),
      },
      {
        path: "portfolio-detail-one/:id",
        name: "portfolio-detail-one",
        component: () => import('@/pages/portfolio/detail-portfolio/portfolio-detail-one.vue'),
      },
      {
        path: "portfolio-detail-two",
        name: "portfolio-detail-two",
        component: () => import('@/pages/portfolio/detail-portfolio/portfolio-detail-two.vue'),
      },
      {
        path: "portfolio-detail-three",
        name: "portfolio-detail-three",
        component: () => import('@/pages/portfolio/detail-portfolio/portfolio-detail-three.vue'),
      },
      {
        path: "portfolio-detail-four",
        name: "portfolio-detail-four",
        component: () => import('@/pages/portfolio/detail-portfolio/portfolio-detail-four.vue'),
      },
      {
        path: ":catchAll(.*)",
        name: "Error",
        component: () => import('@/pages/special-pages/page-error.vue'),
      },
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorGlobal",
    component: () => import('@/pages/special-pages/page-error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;